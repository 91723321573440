import React from "react";
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Modal,
  ModalFooter,
  ModalBody,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/PublicNavbar.js";

class RegisterPage extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        squares1to6: "",
        squares7and8: "",
        username: "",
        usernameValid: false,
        usernameStyle: "",
        email: "",
        emailValid: false,
        emailStyle: "",
        password: "",
        passwordValid: false,
        passwordStyle: "",
        referral: "",
        referralValid: false,
        referralStyle: "",
        legalValid: false,
        legalStyle: "text-left",
        iosSignIn: true,
        modalRegistering: false,

        registeredTitle: "",
        registeredUsername: "",
        registeredFlashInvaders: "",
        registeredEmail: "",
        registeredPassword: "",
        registeredreferral: "",
        registeredMessage: "",
        registeredIcon: "tim-icons icon-check-2"
      };
      this.toggleModalRegistering = this.toggleModalRegistering.bind(this);
  }

  componentDidMount() {
    require("assets/css/blk-design-system-react.css");
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", this.followCursor);
  }

  componentWillUnmount() {
    document.body.classList.toggle("register-page");
    document.documentElement.removeEventListener(
      "mousemove",
      this.followCursor
    );
  }

  followCursor = event => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    this.setState({
      squares1to6:
        "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)",
      squares7and8:
        "perspective(500px) rotateY(" +
        posX * 0.02 +
        "deg) rotateX(" +
        posY * -0.02 +
        "deg)"
    });
  };

  handleInputChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const checked = target.checked;

    if(name==="username"){
        if(value===""){
            this.setState({usernameStyle: ""})
            this.setState({usernameValid: false})
        } else if (value.length > 3 && value.length < 16) {
            this.setState({usernameStyle: "has-success"})
            this.setState({usernameValid: true})
        } else {
            this.setState({usernameStyle: "has-danger"})
            this.setState({usernameValid: false})
        }
        this.setState({username: value})
    }

    if(name==="email"){
        if(value===""){
            this.setState({emailStyle: ""})
            this.setState({emailValid: false})
        // eslint-disable-next-line
        } else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(value)) {
            this.setState({emailStyle: "has-success"})
            this.setState({emailValid: true})
        } else {
            this.setState({emailStyle: "has-danger"})
            this.setState({emailValid: false})
        }
        this.setState({email: value})
    }

    if(name==="password"){
        if(value===""){
            this.setState({passwordStyle: ""})
            this.setState({passwordValid: false})
        // eslint-disable-next-line
        } else if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$€%^&*+=`|{}:;!.?\"()\[\]-]{8,}$/.test(value)) {
            this.setState({passwordStyle: "has-success"})
            this.setState({passwordValid: true})
        } else {
            this.setState({passwordStyle: "has-danger"})
            this.setState({passwordValid: false})
        }
        this.setState({password: value})
    }

    if(name==="referral"){
        if(value===""){
            this.setState({referralStyle: ""})
            this.setState({referralValid: false})
        } else if (value.length > 5) {
            this.setState({referralStyle: "has-success"})
            this.setState({referralValid: true})
        } else {
            this.setState({referralStyle: "has-danger"})
            this.setState({referralValid: false})
        }
        this.setState({referral: value})
    }

    if(name==="legal"){
        if(checked){
            this.setState({legalValid: true})
        } else {
            this.setState({legalValid: false})
        }
        this.setState({legalStyle: "text-left"})
    }

    if(name==="ios"){
        if(checked){
            this.setState({iosSignIn: true})
            console.log("iosSignIn", "true")
        } else {
            this.setState({iosSignIn: false})
            console.log("iosSignIn", "false")
        }
    }
  }

  onSubmit = e => {
      if(!this.state.usernameValid){
          this.setState({usernameStyle: "has-danger"})
      }
      if(!this.state.emailValid){
          this.setState({emailStyle: "has-danger"})
      }
      if(!this.state.passwordValid){
          this.setState({passwordStyle: "has-danger"})
      }
      if(!this.state.referralValid){
          this.setState({referralStyle: "has-danger"})
      }
      if(!this.state.legalValid){
          this.setState({legalStyle: "text-left has-danger"})
      }

      if(this.state.usernameValid && this.state.emailValid && this.state.passwordValid && this.state.referralValid && this.state.legalValid){
          this.callAPI()
      }
  };

  toggleModalRegistering(){
    this.setState({
        modalRegistering: !this.state.modalRegistering
    });
  }

  async callAPI(){
    if(this.state.username!=="" && this.state.email!=="" && this.state.password!=="" && this.state.referral!==""){
        try {
          var formdata = new FormData();
          formdata.append("username", this.state.username);
          formdata.append("email", this.state.email);
          formdata.append("password", this.state.password);
          formdata.append("referral", this.state.referral);
          if(this.state.iosSignIn){
              formdata.append("iOSBeta", "1");
          } else {
              formdata.append("iOSBeta", "0");
          }

          const response = await fetch("https://secure.api.invadex.space/register",
              {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formdata
              }
          );

          const APIResponse = await response.json();
          if(APIResponse.error===0){
              this.toggleModalRegistering();
              this.setState({ registerSuccess: true });

              this.setState({ registeredIcon: "tim-icons icon-check-2"})
              this.setState({ registeredMessage: APIResponse.message });
              this.setState({ registeredUsername: APIResponse.username });
              this.setState({ registeredEmail: APIResponse.email });
              this.setState({ registeredPassword: "••••••••" });
              this.setState({ registeredreferral: APIResponse.referral });
          } else {
              this.toggleModalRegistering();
              this.setState({ registerSuccess: false });

              this.setState({ registeredIcon: "tim-icons icon-simple-remove"})
              this.setState({ registeredMessage: APIResponse.message });
              this.setState({ registeredUsername: APIResponse.username });
              this.setState({ registeredEmail: APIResponse.email });
              this.setState({ registeredPassword: "••••••••" });
              this.setState({ registeredreferral: APIResponse.referral });
          }

        } catch (error) {
          console.error(error);
        }
    }
  };

  render() {
    return (
      <>
        <ExamplesNavbar />
        <div className="wrapper">
          <div className="page-header">
            <div className="page-header-image" />
            <div className="content">
              <Container>
                  <div
                    className="square square-1"
                    id="square1"
                    style={{ transform: this.state.squares1to6 }}
                  />
                  <div
                    className="square square-2"
                    id="square2"
                    style={{ transform: this.state.squares1to6 }}
                  />
                  <div
                    className="square square-3"
                    id="square3"
                    style={{ transform: this.state.squares1to6 }}
                  />
                  <div
                    className="square square-4"
                    id="square4"
                    style={{ transform: this.state.squares1to6 }}
                  />
                  <div
                    className="square square-5"
                    id="square5"
                    style={{ transform: this.state.squares1to6 }}
                  />
                  <div
                    className="square square-6"
                    id="square6"
                    style={{ transform: this.state.squares1to6 }}
                  />
                <Row>
                <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                    <h3 className="display-3 text-white text-left">Why a referral code ?</h3>
                    <p className="text-white mb-3 text-left">Invadex is an nonprofit unofficial project</p>
                    <p className="text-white mb-3 text-left">It is maintained by motivated people but our human and financial ressources are limited.</p>
                    <p className="text-white mb-3 text-left">Although we would like to open the platform to everyone, we must apply restrictions to keep it reliable.<br/></p>
                    { /*<p className="text-white mb-3 text-left">Referral code might be obtained from registred users</p> */ }
                </Col>
                  <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                    <div
                      className="square square-7"
                      id="square7"
                      style={{ transform: this.state.squares7and8 }}
                    />
                    <div
                      className="square square-8"
                      id="square8"
                      style={{ transform: this.state.squares7and8 }}
                    />
                    <Card className="card-register">
                      <CardHeader>
                        <CardImg
                          alt="..."
                          src={require("assets/img/square-purple-1.png")}
                        />
                        <CardTitle tag="h4">Sign up</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Form className="form">
                        <InputGroup
                          className={classnames({ "input-group-focus": this.state.usernameFocus }, this.state.usernameStyle) }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Username"
                            type="text"
                            name="username"
                            onFocus={e => this.setState({ usernameFocus: true })}
                            onBlur={e => this.setState({ usernameFocus: false })}
                            onChange={e => this.handleInputChange(e) }
                          />
                        </InputGroup>
                          <InputGroup
                            className={classnames({ "input-group-focus": this.state.emailFocus }, this.state.emailStyle) }
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-email-85" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="text"
                              name="email"
                              onFocus={e => this.setState({ emailFocus: true })}
                              onBlur={e => this.setState({ emailFocus: false })}
                              onChange={e => this.handleInputChange(e) }
                            />
                          </InputGroup>
                            <InputGroup
                              className={classnames({ "input-group-focus": this.state.passwordFocus }, this.state.passwordStyle) }
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="tim-icons icon-lock-circle" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Password"
                                type="password"
                                name="password"
                                onFocus={e => this.setState({ passwordFocus: true })}
                                onBlur={e => this.setState({ passwordFocus: false })}
                                onChange={e => this.handleInputChange(e) }
                              />
                            </InputGroup>
                            <span class="form-details">Password must have at least 8 characters in length and contain lower case (a-z), upper case (A-Z) and number (0-9)</span>
                          <InputGroup
                            className={classnames({ "input-group-focus": this.state.referralFocus }, this.state.referralStyle) }
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-gift-2" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Referral Code"
                              type="text"
                              name="referral"
                              onFocus={e => this.setState({ referralFocus: true })}
                              onBlur={e => this.setState({ referralFocus: false })}
                              onChange={e => this.handleInputChange(e) }
                            />
                          </InputGroup>
                          <FormGroup check className="text-left">
                            <Label check>
                              <Input type="checkbox" defaultChecked={true} onChange={e => this.handleInputChange(e) } name="ios"/>
                              <span className="form-check-sign" />
                              + Sign up for the iOS App (iPhone only)
                            </Label>
                          </FormGroup>
                          <br/>
                          <br/>
                          <FormGroup check className={this.state.legalStyle}>
                            <Label check>
                              <Input type="checkbox" onChange={e => this.handleInputChange(e) } name="legal"/>
                              <span className="form-check-sign" />I agree to the{" "}
                              <a
                                href="/legal"
                              >
                                terms and conditions
                              </a>
                              .
                            </Label>
                          </FormGroup>
                        </Form>
                      </CardBody>
                      <CardFooter>
                        <Button className="btn-round" color="primary" size="lg" onClick={this.onSubmit}>
                          Sign up
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
                <div className="register-bg" />
              </Container>

              <Modal isOpen={this.state.modalRegistering} toggle={this.toggleModalRegistering} modalClassName="modal-mini modal-black">
                <div className="modal-header justify-content-center">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={this.toggleModalRegistering}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                  <div className="modal-profile">
                    <i className={this.state.registeredIcon} />
                  </div>
                </div>
                <ModalBody className="text-center">
                    <h2>{this.state.registeredTitle}</h2>
                    {this.state.registeredMessage}
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    <Button color="secondary" onClick={this.toggleModalRegistering}>
                        Close
                    </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RegisterPage;
