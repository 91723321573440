import React from "react";

// reactstrap components
import { Container } from "reactstrap";

import Logo from "assets/img/icon-rounded-lq.png"

class Home extends React.Component {
  render() {
    return (
        <div className="page-header header-filter">
          <div className="squares square1" />
          <div className="squares square2" />
          <div className="squares square3" />
          <div className="squares square4" />
          <div className="squares square5" />
          <div className="squares square6" />
          <div className="squares square7" />
            <Container>
                <div className="content-center brand">
                <img src={Logo} alt="Invadex Logo" style={{marginBottom:100, height:150, width:150, borderRadius:25}}/>
                  <h1 className="h1-seo">Invadex</h1>
                  <h4 className="d-none d-sm-block">
                    Community-based Space Invader index
                  </h4>
                </div>
            </Container>
          </div>
    );
  }
}

export default Home;
