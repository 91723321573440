import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Layouts
import Public from "layouts/Public.js";
import Register from "views/RegisterPage.js"

import "assets/css/nucleo-icons.css";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/register" render={props => <Register {...props} />} />
      <Route path="/" render={props => <Public {...props} />} />
      />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
