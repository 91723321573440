import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

// core components
import IndexNavbar from "components/Navbars/PublicNavbar.js";
import Home from "views/Home.js";
import Legal from "views/Legal.js";
import Footer from "components/Footer/PublicFooter.js";

class Index extends React.Component {
  componentDidMount() {
    require("assets/css/blk-design-system-react.css");
    document.body.classList.toggle("index-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("index-page");
  }
  render() {
    return (
      <>
        <IndexNavbar {...this.props} />
        <div className="wrapper">
          <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/legal" exact component={Legal} />
              <Redirect from="*" to="/"/>
          </Switch>
          <Footer />
        </div>
      </>
    );
  }
}

export default Index;
