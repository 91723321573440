import React from "react";
import { Button, Container, Row, Col } from "reactstrap";

class Legal extends React.Component {
    scrollPage = (e, id) => {
    e.preventDefault();
    if (document.getElementById(id) !== null) {
      document.getElementById(id).scrollIntoView();
    }
  };

  render() {
    return (
    <>
    <div className="page-header header-filter">
      <div className="squares square1" />
      <div className="squares square2" />
      <div className="squares square3" />
      <div className="squares square4" />
      <div className="squares square5" />
      <div className="squares square6" />
      <div className="squares square7" />
          <div className="page-header header-filter">
            <div className="squares square1" />
            <div className="squares square2" />
            <div className="squares square3" />
            <div className="squares square4" />
            <div className="squares square5" />
            <div className="squares square6" />
            <div className="squares square7" />
            <Container>
                <div className="content-center brand">
                  <h1 className="h1-seo">Legal</h1>
                  <h4>Is that really what you are looking for ?</h4>
                      <div className="btn-wrapper">
                        <Button
                          className="btn-round"
                          color="primary"
                          onClick={e => this.scrollPage(e, "privacy")}
                        >
                          Yeah, I really like legal stuff
                        </Button>
                      </div>
                </div>
            </Container>
          </div>
      </div>
      <div className="main">
          <div className="section section-nucleo-icons" id="privacy">
            <Container>
              <Row className="justify-content-center">
                <Col lg="8" md="12">
                  <h2 className="title">Privacy Notice</h2>
                  <h4 className="description">
                      <p style={{marginBottom:20}}>Invadex uses GPS position in order to position yourself on a map and localise cities around you.</p>
                      <p style={{marginBottom:20}}>Your email address is used to identify yourself on the platform and is linked to other data you registered in the app such as space-invader positions, flashes, and contribution. Your nickname is used to identify your contributions.</p>
                      <p style={{marginBottom:20}}>We store your data on our own server. It contains your email, nickname, flashes, and spots. We do not store any form of password on our own servers. For your safety reason, we use a recognized third-party authentification platform, Auth0, that also stores your email, nickname and a cryted form of your password in order to securly validate or unvalidate access to your account. We have no means to access Auth0 database.</p>
                      <p style={{marginBottom:20}}>We do not share those data with any other third parties.</p>
                      <p style={{marginBottom:20}}>We do not process those data outside of the application scope.</p>
                      <p style={{marginBottom:20}}>With compliance to the GPDR you have the right of deletion of your data.</p>
                      <p style={{marginBottom:200}}>For any Privacy Data related question, contact : info@invadex.space</p>
                  </h4>
                </Col>
              </Row>
            </Container>
          </div>
      </div>
      </>
    );
  }
}

export default Legal;
